export const AppLinksBanner = () => import('../../src/components/AppLinksBanner.vue' /* webpackChunkName: "components/app-links-banner" */).then(c => wrapFunctional(c.default || c))
export const ApplinksPopup = () => import('../../src/components/ApplinksPopup.vue' /* webpackChunkName: "components/applinks-popup" */).then(c => wrapFunctional(c.default || c))
export const BillingInfoForm = () => import('../../src/components/BillingInfoForm.vue' /* webpackChunkName: "components/billing-info-form" */).then(c => wrapFunctional(c.default || c))
export const Breadcrumb = () => import('../../src/components/Breadcrumb.vue' /* webpackChunkName: "components/breadcrumb" */).then(c => wrapFunctional(c.default || c))
export const CountrySelector = () => import('../../src/components/CountrySelector.vue' /* webpackChunkName: "components/country-selector" */).then(c => wrapFunctional(c.default || c))
export const DriveAppAlert = () => import('../../src/components/DriveAppAlert.vue' /* webpackChunkName: "components/drive-app-alert" */).then(c => wrapFunctional(c.default || c))
export const FcLoader = () => import('../../src/components/FcLoader.vue' /* webpackChunkName: "components/fc-loader" */).then(c => wrapFunctional(c.default || c))
export const ItemDownloaderEventHandler = () => import('../../src/components/ItemDownloaderEventHandler.vue' /* webpackChunkName: "components/item-downloader-event-handler" */).then(c => wrapFunctional(c.default || c))
export const LazyLoader = () => import('../../src/components/LazyLoader.vue' /* webpackChunkName: "components/lazy-loader" */).then(c => wrapFunctional(c.default || c))
export const Skeleton = () => import('../../src/components/Skeleton.vue' /* webpackChunkName: "components/skeleton" */).then(c => wrapFunctional(c.default || c))
export const UserFeedback = () => import('../../src/components/UserFeedback.vue' /* webpackChunkName: "components/user-feedback" */).then(c => wrapFunctional(c.default || c))
export const Alert = () => import('../../src/components/alert.vue' /* webpackChunkName: "components/alert" */).then(c => wrapFunctional(c.default || c))
export const ApiTokenForm = () => import('../../src/components/api-token-form.vue' /* webpackChunkName: "components/api-token-form" */).then(c => wrapFunctional(c.default || c))
export const DelimiterEditor = () => import('../../src/components/delimiter-editor.vue' /* webpackChunkName: "components/delimiter-editor" */).then(c => wrapFunctional(c.default || c))
export const InvoicePrinter = () => import('../../src/components/invoice-printer.vue' /* webpackChunkName: "components/invoice-printer" */).then(c => wrapFunctional(c.default || c))
export const Loader = () => import('../../src/components/loader.vue' /* webpackChunkName: "components/loader" */).then(c => wrapFunctional(c.default || c))
export const Modal = () => import('../../src/components/modal.vue' /* webpackChunkName: "components/modal" */).then(c => wrapFunctional(c.default || c))
export const NavbarItem = () => import('../../src/components/navbar-item.vue' /* webpackChunkName: "components/navbar-item" */).then(c => wrapFunctional(c.default || c))
export const Navbar = () => import('../../src/components/navbar.vue' /* webpackChunkName: "components/navbar" */).then(c => wrapFunctional(c.default || c))
export const Sidebar = () => import('../../src/components/sidebar.vue' /* webpackChunkName: "components/sidebar" */).then(c => wrapFunctional(c.default || c))
export const ContentAccordion = () => import('../../src/components/content/ContentAccordion.vue' /* webpackChunkName: "components/content-accordion" */).then(c => wrapFunctional(c.default || c))
export const ContentCard = () => import('../../src/components/content/ContentCard.vue' /* webpackChunkName: "components/content-card" */).then(c => wrapFunctional(c.default || c))
export const ContentCardHTML = () => import('../../src/components/content/ContentCardHTML.vue' /* webpackChunkName: "components/content-card-h-t-m-l" */).then(c => wrapFunctional(c.default || c))
export const ContentCardInfo = () => import('../../src/components/content/ContentCardInfo.vue' /* webpackChunkName: "components/content-card-info" */).then(c => wrapFunctional(c.default || c))
export const ContentCardLink = () => import('../../src/components/content/ContentCardLink.vue' /* webpackChunkName: "components/content-card-link" */).then(c => wrapFunctional(c.default || c))
export const ContentCardList = () => import('../../src/components/content/ContentCardList.vue' /* webpackChunkName: "components/content-card-list" */).then(c => wrapFunctional(c.default || c))
export const ContentCardParagraph = () => import('../../src/components/content/ContentCardParagraph.vue' /* webpackChunkName: "components/content-card-paragraph" */).then(c => wrapFunctional(c.default || c))
export const ContentCardTitle = () => import('../../src/components/content/ContentCardTitle.vue' /* webpackChunkName: "components/content-card-title" */).then(c => wrapFunctional(c.default || c))
export const FileIoAdvancedOptionSingle = () => import('../../src/components/file-io/AdvancedOptionSingle.vue' /* webpackChunkName: "components/file-io-advanced-option-single" */).then(c => wrapFunctional(c.default || c))
export const FileIoAdvancedOptions = () => import('../../src/components/file-io/AdvancedOptions.vue' /* webpackChunkName: "components/file-io-advanced-options" */).then(c => wrapFunctional(c.default || c))
export const FileIoConvertAllFiles = () => import('../../src/components/file-io/ConvertAllFiles.vue' /* webpackChunkName: "components/file-io-convert-all-files" */).then(c => wrapFunctional(c.default || c))
export const FileIoCropper = () => import('../../src/components/file-io/Cropper.vue' /* webpackChunkName: "components/file-io-cropper" */).then(c => wrapFunctional(c.default || c))
export const FileIoDownloadAll = () => import('../../src/components/file-io/DownloadAll.vue' /* webpackChunkName: "components/file-io-download-all" */).then(c => wrapFunctional(c.default || c))
export const FileIoDownloader = () => import('../../src/components/file-io/Downloader.vue' /* webpackChunkName: "components/file-io-downloader" */).then(c => wrapFunctional(c.default || c))
export const FileIoDropZone = () => import('../../src/components/file-io/DropZone.vue' /* webpackChunkName: "components/file-io-drop-zone" */).then(c => wrapFunctional(c.default || c))
export const FileInput = () => import('../../src/components/file-io/FileInput.vue' /* webpackChunkName: "components/file-input" */).then(c => wrapFunctional(c.default || c))
export const FileInputDropDown = () => import('../../src/components/file-io/FileInputDropDown.vue' /* webpackChunkName: "components/file-input-drop-down" */).then(c => wrapFunctional(c.default || c))
export const FileIoItemDownloader = () => import('../../src/components/file-io/ItemDownloader.vue' /* webpackChunkName: "components/file-io-item-downloader" */).then(c => wrapFunctional(c.default || c))
export const FileIoItemDownloaderTrigger = () => import('../../src/components/file-io/ItemDownloaderTrigger.vue' /* webpackChunkName: "components/file-io-item-downloader-trigger" */).then(c => wrapFunctional(c.default || c))
export const FileIoJobBuilderDialog = () => import('../../src/components/file-io/JobBuilderDialog.vue' /* webpackChunkName: "components/file-io-job-builder-dialog" */).then(c => wrapFunctional(c.default || c))
export const FileIoPresetBox = () => import('../../src/components/file-io/PresetBox.vue' /* webpackChunkName: "components/file-io-preset-box" */).then(c => wrapFunctional(c.default || c))
export const FileIoQrViewer = () => import('../../src/components/file-io/QrViewer.vue' /* webpackChunkName: "components/file-io-qr-viewer" */).then(c => wrapFunctional(c.default || c))
export const FileIoTargetSelector = () => import('../../src/components/file-io/TargetSelector.vue' /* webpackChunkName: "components/file-io-target-selector" */).then(c => wrapFunctional(c.default || c))
export const FileIoTargetSelectorAll = () => import('../../src/components/file-io/TargetSelectorAll.vue' /* webpackChunkName: "components/file-io-target-selector-all" */).then(c => wrapFunctional(c.default || c))
export const FileIoTargetSelectorTrigger = () => import('../../src/components/file-io/TargetSelectorTrigger.vue' /* webpackChunkName: "components/file-io-target-selector-trigger" */).then(c => wrapFunctional(c.default || c))
export const PaymentPaypal = () => import('../../src/components/payment/Paypal.vue' /* webpackChunkName: "components/payment-paypal" */).then(c => wrapFunctional(c.default || c))
export const PaymentStripe = () => import('../../src/components/payment/Stripe.vue' /* webpackChunkName: "components/payment-stripe" */).then(c => wrapFunctional(c.default || c))
export const PaymentStripeCardEdit = () => import('../../src/components/payment/StripeCardEdit.vue' /* webpackChunkName: "components/payment-stripe-card-edit" */).then(c => wrapFunctional(c.default || c))
export const PaymentStripeEdit = () => import('../../src/components/payment/StripeEdit.vue' /* webpackChunkName: "components/payment-stripe-edit" */).then(c => wrapFunctional(c.default || c))
export const PreviewVideoCropper = () => import('../../src/components/preview/VideoCropper.vue' /* webpackChunkName: "components/preview-video-cropper" */).then(c => wrapFunctional(c.default || c))
export const PreviewVideoPreview = () => import('../../src/components/preview/VideoPreview.vue' /* webpackChunkName: "components/preview-video-preview" */).then(c => wrapFunctional(c.default || c))
export const UiAccordion = () => import('../../src/components/ui/Accordion.vue' /* webpackChunkName: "components/ui-accordion" */).then(c => wrapFunctional(c.default || c))
export const UiAccordionSingle = () => import('../../src/components/ui/AccordionSingle.vue' /* webpackChunkName: "components/ui-accordion-single" */).then(c => wrapFunctional(c.default || c))
export const UiAlert = () => import('../../src/components/ui/Alert.vue' /* webpackChunkName: "components/ui-alert" */).then(c => wrapFunctional(c.default || c))
export const UiAlertStatic = () => import('../../src/components/ui/AlertStatic.vue' /* webpackChunkName: "components/ui-alert-static" */).then(c => wrapFunctional(c.default || c))
export const UiAutoDownloadCheckbox = () => import('../../src/components/ui/AutoDownloadCheckbox.vue' /* webpackChunkName: "components/ui-auto-download-checkbox" */).then(c => wrapFunctional(c.default || c))
export const UiBanner = () => import('../../src/components/ui/Banner.vue' /* webpackChunkName: "components/ui-banner" */).then(c => wrapFunctional(c.default || c))
export const UiBannerContainer = () => import('../../src/components/ui/BannerContainer.vue' /* webpackChunkName: "components/ui-banner-container" */).then(c => wrapFunctional(c.default || c))
export const UiBirdEatsBugTrigger = () => import('../../src/components/ui/BirdEatsBugTrigger.vue' /* webpackChunkName: "components/ui-bird-eats-bug-trigger" */).then(c => wrapFunctional(c.default || c))
export const UiBottomDrawer = () => import('../../src/components/ui/BottomDrawer.vue' /* webpackChunkName: "components/ui-bottom-drawer" */).then(c => wrapFunctional(c.default || c))
export const UiDialog = () => import('../../src/components/ui/Dialog.vue' /* webpackChunkName: "components/ui-dialog" */).then(c => wrapFunctional(c.default || c))
export const UiDropDownCss = () => import('../../src/components/ui/DropDownCss.vue' /* webpackChunkName: "components/ui-drop-down-css" */).then(c => wrapFunctional(c.default || c))
export const UiDropdown = () => import('../../src/components/ui/Dropdown.vue' /* webpackChunkName: "components/ui-dropdown" */).then(c => wrapFunctional(c.default || c))
export const UiFloatingFileInput = () => import('../../src/components/ui/FloatingFileInput.vue' /* webpackChunkName: "components/ui-floating-file-input" */).then(c => wrapFunctional(c.default || c))
export const UiFreshdeskWidgetLoader = () => import('../../src/components/ui/FreshdeskWidgetLoader.vue' /* webpackChunkName: "components/ui-freshdesk-widget-loader" */).then(c => wrapFunctional(c.default || c))
export const UiFullPageDrop = () => import('../../src/components/ui/FullPageDrop.vue' /* webpackChunkName: "components/ui-full-page-drop" */).then(c => wrapFunctional(c.default || c))
export const UiImageViewer = () => import('../../src/components/ui/ImageViewer.vue' /* webpackChunkName: "components/ui-image-viewer" */).then(c => wrapFunctional(c.default || c))
export const UiLanguageSelector = () => import('../../src/components/ui/LanguageSelector.vue' /* webpackChunkName: "components/ui-language-selector" */).then(c => wrapFunctional(c.default || c))
export const UiLogo = () => import('../../src/components/ui/Logo.vue' /* webpackChunkName: "components/ui-logo" */).then(c => wrapFunctional(c.default || c))
export const UiMathjax = () => import('../../src/components/ui/Mathjax.vue' /* webpackChunkName: "components/ui-mathjax" */).then(c => wrapFunctional(c.default || c))
export const UiMergeCheckbox = () => import('../../src/components/ui/MergeCheckbox.vue' /* webpackChunkName: "components/ui-merge-checkbox" */).then(c => wrapFunctional(c.default || c))
export const UiNestedLinks = () => import('../../src/components/ui/NestedLinks.vue' /* webpackChunkName: "components/ui-nested-links" */).then(c => wrapFunctional(c.default || c))
export const UiProgressBar = () => import('../../src/components/ui/ProgressBar.vue' /* webpackChunkName: "components/ui-progress-bar" */).then(c => wrapFunctional(c.default || c))
export const UiPrompt = () => import('../../src/components/ui/Prompt.vue' /* webpackChunkName: "components/ui-prompt" */).then(c => wrapFunctional(c.default || c))
export const UiReadMore = () => import('../../src/components/ui/ReadMore.vue' /* webpackChunkName: "components/ui-read-more" */).then(c => wrapFunctional(c.default || c))
export const UiReportMissingExtension = () => import('../../src/components/ui/ReportMissingExtension.vue' /* webpackChunkName: "components/ui-report-missing-extension" */).then(c => wrapFunctional(c.default || c))
export const UiSecurityAndCompliance = () => import('../../src/components/ui/SecurityAndCompliance.vue' /* webpackChunkName: "components/ui-security-and-compliance" */).then(c => wrapFunctional(c.default || c))
export const UiSidePanel = () => import('../../src/components/ui/SidePanel.vue' /* webpackChunkName: "components/ui-side-panel" */).then(c => wrapFunctional(c.default || c))
export const UiSiteNewsAndUpdates = () => import('../../src/components/ui/SiteNewsAndUpdates.vue' /* webpackChunkName: "components/ui-site-news-and-updates" */).then(c => wrapFunctional(c.default || c))
export const UiSiteSearch = () => import('../../src/components/ui/SiteSearch.vue' /* webpackChunkName: "components/ui-site-search" */).then(c => wrapFunctional(c.default || c))
export const UiSmallFileSelectionFeedbackPrompt = () => import('../../src/components/ui/SmallFileSelectionFeedbackPrompt.vue' /* webpackChunkName: "components/ui-small-file-selection-feedback-prompt" */).then(c => wrapFunctional(c.default || c))
export const UiSubcriptionCancelPrompt = () => import('../../src/components/ui/SubcriptionCancelPrompt.vue' /* webpackChunkName: "components/ui-subcription-cancel-prompt" */).then(c => wrapFunctional(c.default || c))
export const UiThumbSortButton = () => import('../../src/components/ui/ThumbSortButton.vue' /* webpackChunkName: "components/ui-thumb-sort-button" */).then(c => wrapFunctional(c.default || c))
export const UiToast = () => import('../../src/components/ui/Toast.vue' /* webpackChunkName: "components/ui-toast" */).then(c => wrapFunctional(c.default || c))
export const UiToastContainer = () => import('../../src/components/ui/ToastContainer.vue' /* webpackChunkName: "components/ui-toast-container" */).then(c => wrapFunctional(c.default || c))
export const UiToolTip = () => import('../../src/components/ui/ToolTip.vue' /* webpackChunkName: "components/ui-tool-tip" */).then(c => wrapFunctional(c.default || c))
export const UiTrustPilotReviewCollector = () => import('../../src/components/ui/TrustPilotReviewCollector.vue' /* webpackChunkName: "components/ui-trust-pilot-review-collector" */).then(c => wrapFunctional(c.default || c))
export const UiTrustPilotReviews = () => import('../../src/components/ui/TrustPilotReviews.vue' /* webpackChunkName: "components/ui-trust-pilot-reviews" */).then(c => wrapFunctional(c.default || c))
export const UiTrustPilotReviewsCollapsible = () => import('../../src/components/ui/TrustPilotReviewsCollapsible.vue' /* webpackChunkName: "components/ui-trust-pilot-reviews-collapsible" */).then(c => wrapFunctional(c.default || c))
export const UiUspCard = () => import('../../src/components/ui/UspCard.vue' /* webpackChunkName: "components/ui-usp-card" */).then(c => wrapFunctional(c.default || c))
export const FileIoDriversDropbox = () => import('../../src/components/file-io/drivers/Dropbox.vue' /* webpackChunkName: "components/file-io-drivers-dropbox" */).then(c => wrapFunctional(c.default || c))
export const FileIoDriversGoogle = () => import('../../src/components/file-io/drivers/Google.vue' /* webpackChunkName: "components/file-io-drivers-google" */).then(c => wrapFunctional(c.default || c))
export const FileIoDriversOnedrive = () => import('../../src/components/file-io/drivers/Onedrive.vue' /* webpackChunkName: "components/file-io-drivers-onedrive" */).then(c => wrapFunctional(c.default || c))
export const FileIoUploadFromDevice = () => import('../../src/components/file-io/Upload/UploadFromDevice.vue' /* webpackChunkName: "components/file-io-upload-from-device" */).then(c => wrapFunctional(c.default || c))
export const FileIoUploadFromUrl = () => import('../../src/components/file-io/Upload/UploadFromUrl.vue' /* webpackChunkName: "components/file-io-upload-from-url" */).then(c => wrapFunctional(c.default || c))
export const FileIoFileBulkItem = () => import('../../src/components/file-io/file/BulkItem.vue' /* webpackChunkName: "components/file-io-file-bulk-item" */).then(c => wrapFunctional(c.default || c))
export const FileIoFileConversionList = () => import('../../src/components/file-io/file/ConversionList.vue' /* webpackChunkName: "components/file-io-file-conversion-list" */).then(c => wrapFunctional(c.default || c))
export const FileIoFileConversionListItem = () => import('../../src/components/file-io/file/ConversionListItem.vue' /* webpackChunkName: "components/file-io-file-conversion-list-item" */).then(c => wrapFunctional(c.default || c))
export const FIleList = () => import('../../src/components/file-io/file/FIleList.vue' /* webpackChunkName: "components/f-ile-list" */).then(c => wrapFunctional(c.default || c))
export const FileListItem = () => import('../../src/components/file-io/file/FileListItem.vue' /* webpackChunkName: "components/file-list-item" */).then(c => wrapFunctional(c.default || c))
export const FileListItemLoader = () => import('../../src/components/file-io/file/FileListItemLoader.vue' /* webpackChunkName: "components/file-list-item-loader" */).then(c => wrapFunctional(c.default || c))
export const FileName = () => import('../../src/components/file-io/file/FileName.vue' /* webpackChunkName: "components/file-name" */).then(c => wrapFunctional(c.default || c))
export const FileIoFileThumbList = () => import('../../src/components/file-io/file/ThumbList.vue' /* webpackChunkName: "components/file-io-file-thumb-list" */).then(c => wrapFunctional(c.default || c))
export const FileIoFileThumbListItem = () => import('../../src/components/file-io/file/ThumbListItem.vue' /* webpackChunkName: "components/file-io-file-thumb-list-item" */).then(c => wrapFunctional(c.default || c))
export const UiIconActivityIcon = () => import('../../src/components/ui/icon/ActivityIcon.vue' /* webpackChunkName: "components/ui-icon-activity-icon" */).then(c => wrapFunctional(c.default || c))
export const UiIconAlertCircleIcon = () => import('../../src/components/ui/icon/AlertCircleIcon.vue' /* webpackChunkName: "components/ui-icon-alert-circle-icon" */).then(c => wrapFunctional(c.default || c))
export const UiIconAlphaSortIcon = () => import('../../src/components/ui/icon/AlphaSortIcon.vue' /* webpackChunkName: "components/ui-icon-alpha-sort-icon" */).then(c => wrapFunctional(c.default || c))
export const UiIconAlphaSortReverseIcon = () => import('../../src/components/ui/icon/AlphaSortReverseIcon.vue' /* webpackChunkName: "components/ui-icon-alpha-sort-reverse-icon" */).then(c => wrapFunctional(c.default || c))
export const UiIconAmexLogo = () => import('../../src/components/ui/icon/AmexLogo.vue' /* webpackChunkName: "components/ui-icon-amex-logo" */).then(c => wrapFunctional(c.default || c))
export const UiIconAnchorIcon = () => import('../../src/components/ui/icon/AnchorIcon.vue' /* webpackChunkName: "components/ui-icon-anchor-icon" */).then(c => wrapFunctional(c.default || c))
export const UiIconArrowRightIcon = () => import('../../src/components/ui/icon/ArrowRightIcon.vue' /* webpackChunkName: "components/ui-icon-arrow-right-icon" */).then(c => wrapFunctional(c.default || c))
export const UiIconBellIcon = () => import('../../src/components/ui/icon/BellIcon.vue' /* webpackChunkName: "components/ui-icon-bell-icon" */).then(c => wrapFunctional(c.default || c))
export const UiIconBlockStarIcon = () => import('../../src/components/ui/icon/BlockStarIcon.vue' /* webpackChunkName: "components/ui-icon-block-star-icon" */).then(c => wrapFunctional(c.default || c))
export const UiIconBriefcaseIcon = () => import('../../src/components/ui/icon/BriefcaseIcon.vue' /* webpackChunkName: "components/ui-icon-briefcase-icon" */).then(c => wrapFunctional(c.default || c))
export const UiIconCarouselNextIcon = () => import('../../src/components/ui/icon/CarouselNextIcon.vue' /* webpackChunkName: "components/ui-icon-carousel-next-icon" */).then(c => wrapFunctional(c.default || c))
export const UiIconCreditCardIcon = () => import('../../src/components/ui/icon/CreditCardIcon.vue' /* webpackChunkName: "components/ui-icon-credit-card-icon" */).then(c => wrapFunctional(c.default || c))
export const UiIconDiscoverPayLogo = () => import('../../src/components/ui/icon/DiscoverPayLogo.vue' /* webpackChunkName: "components/ui-icon-discover-pay-logo" */).then(c => wrapFunctional(c.default || c))
export const UiIconFileTextIcon = () => import('../../src/components/ui/icon/FileTextIcon.vue' /* webpackChunkName: "components/ui-icon-file-text-icon" */).then(c => wrapFunctional(c.default || c))
export const UiIconFireIcon = () => import('../../src/components/ui/icon/FireIcon.vue' /* webpackChunkName: "components/ui-icon-fire-icon" */).then(c => wrapFunctional(c.default || c))
export const UiIcon = () => import('../../src/components/ui/icon/Icon.vue' /* webpackChunkName: "components/ui-icon" */).then(c => wrapFunctional(c.default || c))
export const UiIconBadgeLinear = () => import('../../src/components/ui/icon/IconBadgeLinear.vue' /* webpackChunkName: "components/ui-icon-badge-linear" */).then(c => wrapFunctional(c.default || c))
export const UiIconCode = () => import('../../src/components/ui/icon/IconCode.vue' /* webpackChunkName: "components/ui-icon-code" */).then(c => wrapFunctional(c.default || c))
export const UiIconDiamond = () => import('../../src/components/ui/icon/IconDiamond.vue' /* webpackChunkName: "components/ui-icon-diamond" */).then(c => wrapFunctional(c.default || c))
export const UiIconDropbox = () => import('../../src/components/ui/icon/IconDropbox.vue' /* webpackChunkName: "components/ui-icon-dropbox" */).then(c => wrapFunctional(c.default || c))
export const UiIconFacebook = () => import('../../src/components/ui/icon/IconFacebook.vue' /* webpackChunkName: "components/ui-icon-facebook" */).then(c => wrapFunctional(c.default || c))
export const UiIconFirewallLinear = () => import('../../src/components/ui/icon/IconFirewallLinear.vue' /* webpackChunkName: "components/ui-icon-firewall-linear" */).then(c => wrapFunctional(c.default || c))
export const UiIconFolder = () => import('../../src/components/ui/icon/IconFolder.vue' /* webpackChunkName: "components/ui-icon-folder" */).then(c => wrapFunctional(c.default || c))
export const UiIconGlobe = () => import('../../src/components/ui/icon/IconGlobe.vue' /* webpackChunkName: "components/ui-icon-globe" */).then(c => wrapFunctional(c.default || c))
export const UiIconGoogleDrive = () => import('../../src/components/ui/icon/IconGoogleDrive.vue' /* webpackChunkName: "components/ui-icon-google-drive" */).then(c => wrapFunctional(c.default || c))
export const UiIconImageLinear = () => import('../../src/components/ui/icon/IconImageLinear.vue' /* webpackChunkName: "components/ui-icon-image-linear" */).then(c => wrapFunctional(c.default || c))
export const UiIconInstagram = () => import('../../src/components/ui/icon/IconInstagram.vue' /* webpackChunkName: "components/ui-icon-instagram" */).then(c => wrapFunctional(c.default || c))
export const UiIconLink = () => import('../../src/components/ui/icon/IconLink.vue' /* webpackChunkName: "components/ui-icon-link" */).then(c => wrapFunctional(c.default || c))
export const UiIconLinkedIn = () => import('../../src/components/ui/icon/IconLinkedIn.vue' /* webpackChunkName: "components/ui-icon-linked-in" */).then(c => wrapFunctional(c.default || c))
export const UiIconOnedrive = () => import('../../src/components/ui/icon/IconOnedrive.vue' /* webpackChunkName: "components/ui-icon-onedrive" */).then(c => wrapFunctional(c.default || c))
export const UiIconQRCode = () => import('../../src/components/ui/icon/IconQRCode.vue' /* webpackChunkName: "components/ui-icon-q-r-code" */).then(c => wrapFunctional(c.default || c))
export const UiIconTwitter = () => import('../../src/components/ui/icon/IconTwitter.vue' /* webpackChunkName: "components/ui-icon-twitter" */).then(c => wrapFunctional(c.default || c))
export const UiIconKeyIcon = () => import('../../src/components/ui/icon/KeyIcon.vue' /* webpackChunkName: "components/ui-icon-key-icon" */).then(c => wrapFunctional(c.default || c))
export const UiIconLayersIcon = () => import('../../src/components/ui/icon/LayersIcon.vue' /* webpackChunkName: "components/ui-icon-layers-icon" */).then(c => wrapFunctional(c.default || c))
export const UiIconLockIcon = () => import('../../src/components/ui/icon/LockIcon.vue' /* webpackChunkName: "components/ui-icon-lock-icon" */).then(c => wrapFunctional(c.default || c))
export const UiIconLogoAdobe = () => import('../../src/components/ui/icon/LogoAdobe.vue' /* webpackChunkName: "components/ui-icon-logo-adobe" */).then(c => wrapFunctional(c.default || c))
export const UiIconLogoAtnt = () => import('../../src/components/ui/icon/LogoAtnt.vue' /* webpackChunkName: "components/ui-icon-logo-atnt" */).then(c => wrapFunctional(c.default || c))
export const UiIconLogoChromecast = () => import('../../src/components/ui/icon/LogoChromecast.vue' /* webpackChunkName: "components/ui-icon-logo-chromecast" */).then(c => wrapFunctional(c.default || c))
export const UiIconLogoNespresso = () => import('../../src/components/ui/icon/LogoNespresso.vue' /* webpackChunkName: "components/ui-icon-logo-nespresso" */).then(c => wrapFunctional(c.default || c))
export const UiIconLogoNetflix = () => import('../../src/components/ui/icon/LogoNetflix.vue' /* webpackChunkName: "components/ui-icon-logo-netflix" */).then(c => wrapFunctional(c.default || c))
export const UiIconLogoPaypal = () => import('../../src/components/ui/icon/LogoPaypal.vue' /* webpackChunkName: "components/ui-icon-logo-paypal" */).then(c => wrapFunctional(c.default || c))
export const UiIconLogoRing = () => import('../../src/components/ui/icon/LogoRing.vue' /* webpackChunkName: "components/ui-icon-logo-ring" */).then(c => wrapFunctional(c.default || c))
export const UiIconLogoStripe = () => import('../../src/components/ui/icon/LogoStripe.vue' /* webpackChunkName: "components/ui-icon-logo-stripe" */).then(c => wrapFunctional(c.default || c))
export const UiIconLogoVerizon = () => import('../../src/components/ui/icon/LogoVerizon.vue' /* webpackChunkName: "components/ui-icon-logo-verizon" */).then(c => wrapFunctional(c.default || c))
export const UiIconLogoYelp = () => import('../../src/components/ui/icon/LogoYelp.vue' /* webpackChunkName: "components/ui-icon-logo-yelp" */).then(c => wrapFunctional(c.default || c))
export const UiIconMasterCardLogo = () => import('../../src/components/ui/icon/MasterCardLogo.vue' /* webpackChunkName: "components/ui-icon-master-card-logo" */).then(c => wrapFunctional(c.default || c))
export const UiIconPaypalIcon = () => import('../../src/components/ui/icon/PaypalIcon.vue' /* webpackChunkName: "components/ui-icon-paypal-icon" */).then(c => wrapFunctional(c.default || c))
export const UiIconPlanSwitcherPointer = () => import('../../src/components/ui/icon/PlanSwitcherPointer.vue' /* webpackChunkName: "components/ui-icon-plan-switcher-pointer" */).then(c => wrapFunctional(c.default || c))
export const UiIconRoundCheckIcon = () => import('../../src/components/ui/icon/RoundCheckIcon.vue' /* webpackChunkName: "components/ui-icon-round-check-icon" */).then(c => wrapFunctional(c.default || c))
export const UiIconRoundTickIcon = () => import('../../src/components/ui/icon/RoundTickIcon.vue' /* webpackChunkName: "components/ui-icon-round-tick-icon" */).then(c => wrapFunctional(c.default || c))
export const UiIconShoppingCartIcon = () => import('../../src/components/ui/icon/ShoppingCartIcon.vue' /* webpackChunkName: "components/ui-icon-shopping-cart-icon" */).then(c => wrapFunctional(c.default || c))
export const UiIconSlidersIcon = () => import('../../src/components/ui/icon/SlidersIcon.vue' /* webpackChunkName: "components/ui-icon-sliders-icon" */).then(c => wrapFunctional(c.default || c))
export const UiIconSmileyIcon = () => import('../../src/components/ui/icon/SmileyIcon.vue' /* webpackChunkName: "components/ui-icon-smiley-icon" */).then(c => wrapFunctional(c.default || c))
export const UiIconStarIcon = () => import('../../src/components/ui/icon/StarIcon.vue' /* webpackChunkName: "components/ui-icon-star-icon" */).then(c => wrapFunctional(c.default || c))
export const UiIconStarOutlineIcon = () => import('../../src/components/ui/icon/StarOutlineIcon.vue' /* webpackChunkName: "components/ui-icon-star-outline-icon" */).then(c => wrapFunctional(c.default || c))
export const UiIconTrustPilotIcon = () => import('../../src/components/ui/icon/TrustPilotIcon.vue' /* webpackChunkName: "components/ui-icon-trust-pilot-icon" */).then(c => wrapFunctional(c.default || c))
export const UiIconTrustPilotLogo = () => import('../../src/components/ui/icon/TrustPilotLogo.vue' /* webpackChunkName: "components/ui-icon-trust-pilot-logo" */).then(c => wrapFunctional(c.default || c))
export const UiIconUserIcon = () => import('../../src/components/ui/icon/UserIcon.vue' /* webpackChunkName: "components/ui-icon-user-icon" */).then(c => wrapFunctional(c.default || c))
export const UiIconUsersIcon = () => import('../../src/components/ui/icon/UsersIcon.vue' /* webpackChunkName: "components/ui-icon-users-icon" */).then(c => wrapFunctional(c.default || c))
export const UiIconVisaLogo = () => import('../../src/components/ui/icon/VisaLogo.vue' /* webpackChunkName: "components/ui-icon-visa-logo" */).then(c => wrapFunctional(c.default || c))
export const UiFormBasicsSelect = () => import('../../src/components/ui/form/basics/Select.vue' /* webpackChunkName: "components/ui-form-basics-select" */).then(c => wrapFunctional(c.default || c))
export const UiFormBasicsSelectSearch = () => import('../../src/components/ui/form/basics/SelectSearch.vue' /* webpackChunkName: "components/ui-form-basics-select-search" */).then(c => wrapFunctional(c.default || c))
export const UiFormBasicsSelectSlotted = () => import('../../src/components/ui/form/basics/SelectSlotted.vue' /* webpackChunkName: "components/ui-form-basics-select-slotted" */).then(c => wrapFunctional(c.default || c))
export const UiFormButtonsButtonBurgerMenu = () => import('../../src/components/ui/form/buttons/ButtonBurgerMenu.vue' /* webpackChunkName: "components/ui-form-buttons-button-burger-menu" */).then(c => wrapFunctional(c.default || c))
export const UiFormButtonsButtonCopy = () => import('../../src/components/ui/form/buttons/ButtonCopy.vue' /* webpackChunkName: "components/ui-form-buttons-button-copy" */).then(c => wrapFunctional(c.default || c))
export const UiFormFieldsCheckbox = () => import('../../src/components/ui/form/fields/Checkbox.vue' /* webpackChunkName: "components/ui-form-fields-checkbox" */).then(c => wrapFunctional(c.default || c))
export const UiFormFieldsColor = () => import('../../src/components/ui/form/fields/Color.vue' /* webpackChunkName: "components/ui-form-fields-color" */).then(c => wrapFunctional(c.default || c))
export const UiFormFieldsCropToggle = () => import('../../src/components/ui/form/fields/CropToggle.vue' /* webpackChunkName: "components/ui-form-fields-crop-toggle" */).then(c => wrapFunctional(c.default || c))
export const UiFormFieldsCustomDimension = () => import('../../src/components/ui/form/fields/CustomDimension.vue' /* webpackChunkName: "components/ui-form-fields-custom-dimension" */).then(c => wrapFunctional(c.default || c))
export const UiFormFieldsFieldChild = () => import('../../src/components/ui/form/fields/FieldChild.vue' /* webpackChunkName: "components/ui-form-fields-field-child" */).then(c => wrapFunctional(c.default || c))
export const UiFormFieldsFieldParent = () => import('../../src/components/ui/form/fields/FieldParent.vue' /* webpackChunkName: "components/ui-form-fields-field-parent" */).then(c => wrapFunctional(c.default || c))
export const UiFormFieldsFileInput = () => import('../../src/components/ui/form/fields/FileInput.vue' /* webpackChunkName: "components/ui-form-fields-file-input" */).then(c => wrapFunctional(c.default || c))
export const UiFormFieldsRadio = () => import('../../src/components/ui/form/fields/Radio.vue' /* webpackChunkName: "components/ui-form-fields-radio" */).then(c => wrapFunctional(c.default || c))
export const UiFormFieldsRadioButtons = () => import('../../src/components/ui/form/fields/RadioButtons.vue' /* webpackChunkName: "components/ui-form-fields-radio-buttons" */).then(c => wrapFunctional(c.default || c))
export const UiFormFieldsSelect = () => import('../../src/components/ui/form/fields/Select.vue' /* webpackChunkName: "components/ui-form-fields-select" */).then(c => wrapFunctional(c.default || c))
export const UiFormFieldsSlider = () => import('../../src/components/ui/form/fields/Slider.vue' /* webpackChunkName: "components/ui-form-fields-slider" */).then(c => wrapFunctional(c.default || c))
export const UiFormFieldsText = () => import('../../src/components/ui/form/fields/Text.vue' /* webpackChunkName: "components/ui-form-fields-text" */).then(c => wrapFunctional(c.default || c))
export const UiFormFieldsTimePicker = () => import('../../src/components/ui/form/fields/TimePicker.vue' /* webpackChunkName: "components/ui-form-fields-time-picker" */).then(c => wrapFunctional(c.default || c))
export const UiFormFieldsTimePickerScroll = () => import('../../src/components/ui/form/fields/TimePickerScroll.vue' /* webpackChunkName: "components/ui-form-fields-time-picker-scroll" */).then(c => wrapFunctional(c.default || c))
export const UiFormSlider = () => import('../../src/components/ui/form/slider/index.js' /* webpackChunkName: "components/ui-form-slider" */).then(c => wrapFunctional(c.default || c))
export const UiFormSliderVue2Slider = () => import('../../src/components/ui/form/slider/vue2-slider.vue' /* webpackChunkName: "components/ui-form-slider-vue2-slider" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
